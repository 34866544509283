/*
 ######       ###     ##         ########   ########   ####  ########   
##    ##     ## ##    ##         ##         ##     ##   ##   ##         
##          ##   ##   ##         ##         ##     ##   ##   ##         
##   ####  ##     ##  ##         ######     ########    ##   ######     
##    ##   #########  ##         ##         ##   ##     ##   ##         
##    ##   ##     ##  ##         ##         ##    ##    ##   ##         
 ######    ##     ##  ########   ########   ##     ##  ####  ########   
*/



function getWindowWidth() {
	return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

var scrolling = false;
var slider    = false;

var scrolling = false;
	slider    = false;
	$tiles    = $('#detail');
	$handler  = $('li', $tiles);
	$thumbs   = $('img', $handler);
	$main     = $('.inner-detail');
	$window   = $(window);
	$document = $(document);
	options = {
		resizeDelay: 20,
		autoResize: true, 
		container: $main,
		offset: 0, 
		itemWidth: '100',
		flexibleWidth: function () {
			if( getWindowWidth() < 1024 ){
				return '100%';
			}else{
				return '50%';
			}
		}
	};

/**
 * Lance PhotoSwipe Gallery
 */
function openPhotoSwipe(event) {

	var $thumb = $(event.currentTarget),
		items = [],
		item,
		options,
		gallery;

	options = {
	    closeOnScroll: false,
	    shareEl:       false,
	    counterEl:     false,
		bgOpacity: 0.8,
	    index:         $thumb.index()-1
	};

	$('li.photoswipe', $tiles).each(function() {
		item = {
			src: $(this).attr('data-src'),
			w:   $(this).attr('data-width'),
			h:   $(this).attr('data-height')
        };
		items.push(item);
	});

	gallery = new PhotoSwipe( $(".pswp")[0], PhotoSwipeUI_Default, items, options);
	gallery.init();

}

function applyLayout() {

	var loadedImages = 0;
	var imgLoad      = imagesLoaded( $tiles );

	if( $thumbs.length == 0 ){

		$handler = $('li', $tiles);
		$handler.removeClass('wookmark-inactive');
		wookmark = new Wookmark('#detail', options);
		$(".spinner").hide();
		$('li.photoswipe', $tiles).unbind('click').click(openPhotoSwipe);
		return false;
		
	}

	imgLoad.on("always", function (instance) {

		$('.progress-bar').css('width', '0%');
		$('.progress-bar-text').html('0%');

		$handler = $('li', $tiles);
		$handler.removeClass('wookmark-inactive');
		wookmark = new Wookmark('#detail', options);

		$(".spinner").hide();

		/**
		 * Photoswipe
		 */
		$('li.photoswipe', $tiles).unbind('click').click(openPhotoSwipe);

	}).on("progress", function (instance, image) {

		// Update progress bar after each image has loaded and remove loading state
		percentage = (++loadedImages / $('li.photoswipe', $tiles).length * 100);
		$('.progress-bar').css('width', Math.ceil(percentage) + '%');
		$('.progress-bar-text').html(Math.ceil(percentage) + '%');

	});

}

/*
##     ##     ###     ########   
###   ###    ## ##    ##     ##  
#### ####   ##   ##   ##     ##  
## ### ##  ##     ##  ########   
##     ##  #########  ##         
##     ##  ##     ##  ##         
##     ##  ##     ##  ##         
*/

var stylez = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
var lat         = $('#map-contact').attr('data-lat');
var lng         = $('#map-contact').attr('data-lng');
var defaultZoom = $('#map-contact').attr('data-zoom');

var loadMap = function() {
	simpleMap = new GMaps( {
		div:              '#map-contact',
		lat:              lat,
		lng:              lng,
		zoom:             parseFloat(defaultZoom),
		disableDefaultUI: true,
		zoomControl:      true,
		zoomControlOptions: {
	        position: google.maps.ControlPosition.TOP_LEFT
	    },
		scrollwheel:      false,
		styles:           stylez
		// styles:           [{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"},{"color":"#052366"},{"saturation":"-70"},{"lightness":"85"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45},{"visibility":"on"}]},{"featureType":"road","elementType":"geometry","stylers":[{"saturation":"-18"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.local","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#57677a"},{"visibility":"on"}]}]
	});

	var image = new google.maps.MarkerImage('/images/pointer.png', null, null, null, new google.maps.Size(24,38));
	
	if( $("#map-contact").length > 0 ){
		simpleMap.addMarker({
			lat:  lat,
			lng:  lng,
			icon: image
		});
	}

	
};

/*
      ##    #######   ##     ##  ########   ########   ##    ##   
      ##   ##     ##  ##     ##  ##         ##     ##   ##  ##    
      ##   ##     ##  ##     ##  ##         ##     ##    ####     
      ##   ##     ##  ##     ##  ######     ########      ##      
##    ##   ##  ## ##  ##     ##  ##         ##   ##       ##      
##    ##   ##    ##   ##     ##  ##         ##    ##      ##      
 ######     ##### ##   #######   ########   ##     ##     ##      
*/

var resizeSlider = null;

$(document).ready(function() {


	FastClick.attach(document.body);

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	/*
	##     ##  ########   ##    ##   ##     ##  
	###   ###  ##         ###   ##   ##     ##  
	#### ####  ##         ####  ##   ##     ##  
	## ### ##  ######     ## ## ##   ##     ##  
	##     ##  ##         ##  ####   ##     ##  
	##     ##  ##         ##   ###   ##     ##  
	##     ##  ########   ##    ##    #######   
	*/

	$('.nav-toggle').click(function(){
		$('.nav-icon, .nav-main, .overlay-content').toggleClass('open');
		$('.overlay-content').click(function(){
			$('.nav-icon, .nav-main, .overlay-content').removeClass('open');
		});
	});

	var waypoints = $('.container').waypoint({
		handler: function(direction) {
			$(".header").toggleClass('stuck');
		},
		offset: 120 
	});

	$(".go-up").click(function(e){
		e.preventDefault();
		$('html,body').animate({scrollTop : 0},800);
	});

	/*
	   ###     ##    ##   ####  ##     ##   ######    
	  ## ##    ###   ##    ##   ###   ###  ##    ##   
	 ##   ##   ####  ##    ##   #### ####  ##         
	##     ##  ## ## ##    ##   ## ### ##   ######    
	#########  ##  ####    ##   ##     ##        ##   
	##     ##  ##   ###    ##   ##     ##  ##    ##   
	##     ##  ##    ##   ####  ##     ##   ######    
	*/


	var rotateText = $('.has-rotate-text').waypoint(function(direction) {
		$(this.element).toggleClass('in-view'); 
	}, {
		offset: '25%'
	});

	// $('.has-svg-circle').on({
	// 	mouseenter: function(){
	// 		// alert('yo!');
	// 		$(this).addClass('animated');
	// 	},
	// 	mouseleave: function(){
	// 		$(this).removeClass('animated');
	// 	}
	// });

	/*
	##     ##  ####  ########   ########    #######   
	##     ##   ##   ##     ##  ##         ##     ##  
	##     ##   ##   ##     ##  ##         ##     ##  
	##     ##   ##   ##     ##  ######     ##     ##  
	 ##   ##    ##   ##     ##  ##         ##     ##  
	  ## ##     ##   ##     ##  ##         ##     ##  
	   ###     ####  ########   ########    #######   
	*/

	function videoIsPlaying() {
		return function() {
			$(this).addClass('is-playing');
		};
	}

	var fade_in_videos = $('.fade-in-video');
	for( i=0; i < fade_in_videos.length; i++ ) {
		fade_in_videos[i].addEventListener("playing", videoIsPlaying());
	}

	/*
	########   ########      ###     ##         ####   ######       ###     ########   ####   #######   ##    ##   
	##     ##  ##           ## ##    ##          ##   ##    ##     ## ##       ##       ##   ##     ##  ###   ##   
	##     ##  ##          ##   ##   ##          ##   ##          ##   ##      ##       ##   ##     ##  ####  ##   
	########   ######     ##     ##  ##          ##    ######    ##     ##     ##       ##   ##     ##  ## ## ##   
	##   ##    ##         #########  ##          ##         ##   #########     ##       ##   ##     ##  ##  ####   
	##    ##   ##         ##     ##  ##          ##   ##    ##   ##     ##     ##       ##   ##     ##  ##   ###   
	##     ##  ########   ##     ##  ########   ####   ######    ##     ##     ##      ####   #######   ##    ##   
	*/
	if( $("body").hasClass('realisation-detail') || $("body").hasClass('renovation-detail') ){
		applyLayout();
	}


	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/
	/**
	 * Gestion de l'élément de formulaire ayant le focus
	 */
	$('.form__input input, .form__input textarea').focus(function(){
		$(this).parent().addClass('current');
	}).blur(function() {
		$(this).parent().removeClass('current');
	}); 

	$("form").validationEngine({ 
		scroll: false,
		showPrompts: false,
		onFieldFailure: function(_input){
			_input.parent('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.parent('li').removeClass('error');
		}
	});

	$("#fcontact").submit(function(){
		if($("#fcontact").validationEngine('validate')){
			var box     = $(this);
			var div     = $(this).prev('.form-return').addClass('sending').find('span').empty().html('Envoi en cours...');
			var data    = $(this).serialize() + '&act=envoi';
			var url     = $(this).attr('action');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : url,
				success : function(retour) {
					ga('send', 'event', 'Formulaire', 'submit', 'Contact'); 
					box.toggleClass('sending');
					div.empty().append(retour);
				}
			});
		}
		return false;
	});

	if ( $('#map-contact').length > 0 ) {
		loadMap();
	}

	jQuery('#camera_wrap_1').camera({
		fx: 'blindCurtainSliceBottom',
		loader: 'none',
		navigation: false,
		playPause: false,
		pagination: false,
		pauseOnClick: false,
		time: 3000,
		height: '40%'
	});

	/*
	 ######    ##         ####  ########   ########   ########   
	##    ##   ##          ##   ##     ##  ##         ##     ##  
	##         ##          ##   ##     ##  ##         ##     ##  
	 ######    ##          ##   ##     ##  ######     ########   
	      ##   ##          ##   ##     ##  ##         ##   ##    
	##    ##   ##          ##   ##     ##  ##         ##    ##   
	 ######    ########   ####  ########   ########   ##     ## 
	*/
	var slider = (function() {
		var init = function() {
			resizeSlider();
			setTimeout(function(){
				var InfiniteSliderCSS = new InfiniteSlider($('#block-slider'),1250,6000,'css','easeOutQuad',false,true);

				// Next
				// $('.panel-1, .panel-2, .panel-3', $('#block-slider')).on('click', function(){
				// 	$('.slider__nav a', $('#slider')).trigger('click');
				// });
			}, 950);
		};
		return {
	        init: init
	    };
	})();

	resizeSlider = function() {
		var sliderWidth  = $('.slider').innerWidth();
		var sliderHeight = $('.slider').innerHeight();


		$('.slide .img').each(function(index) {
			$(this).css('width', sliderWidth).css('height', sliderHeight);
		});
	};

	slider.init();

	// var pinOneButton = document.querySelector('.pinterest');
    $('.pinterest').on('click', function(e) {
    	e.stopPropagation();
    	e.preventDefault();
        PinUtils.pinOne({
            media: $(this).data('media'),
            description: $(this).data('description')
        });
    });

    /*
	########   ########      ###      ######    ##    ##        ##         ####  ##    ##   ##    ##   
	   ##      ##     ##    ## ##    ##    ##   ##   ##         ##          ##   ###   ##   ##   ##    
	   ##      ##     ##   ##   ##   ##         ##  ##          ##          ##   ####  ##   ##  ##     
	   ##      ########   ##     ##  ##         #####           ##          ##   ## ## ##   #####      
	   ##      ##   ##    #########  ##         ##  ##          ##          ##   ##  ####   ##  ##     
	   ##      ##    ##   ##     ##  ##    ##   ##   ##         ##          ##   ##   ###   ##   ##    
	   ##      ##     ##  ##     ##   ######    ##    ##        ########   ####  ##    ##   ##    ##   
	*/
	$('[event-category]').click(function(e) {
		ga('send', 'event', $(this).attr('event-category'), 'click', $(this).attr('event-label'));
	});

});

/*
########   ########    ######    ####  ########   ########   
##     ##  ##         ##    ##    ##        ##    ##         
##     ##  ##         ##          ##       ##     ##         
########   ######      ######     ##      ##      ######     
##   ##    ##               ##    ##     ##       ##         
##    ##   ##         ##    ##    ##    ##        ##         
##     ##  ########    ######    ####  ########   ########   
*/

(function($,sr){

  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {
      var timeout;

      return function debounced () {
          var obj = this, args = arguments;
          function delayed () {
              if (!execAsap)
                  func.apply(obj, args);
              timeout = null;
          }

          if (timeout)
              clearTimeout(timeout);
          else if (execAsap)
              func.apply(obj, args);

          timeout = setTimeout(delayed, threshold || 200);
      };
  };
  // smartresize 
  jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery,'smartresize');

jQuery(window).smartresize(function(){

	resizeSlider();

});