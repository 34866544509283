var $hoverMe = $('.image-reveal');
var $colorOverlay = $('.image-reveal--color');
var $whiteOverlay = $('.image-reveal--white');
var isAnimated = false;

var controller = new ScrollMagic.Controller();

var sceneImage = [];
var tween2 = [];

jQuery('.image-reveal').each(function(index){

    var $hoverMe      = $(this);
    var $colorOverlay = $('.image-reveal--color', $hoverMe);
    var $whiteOverlay = $('.image-reveal--white', $hoverMe);

    var windowHeight  = $(window).height();
    var elementOffset = $(this).offset().top;
    var elementHeight = $(this).outerHeight();
    var offset        = ( elementOffset - windowHeight );

    $whiteOverlay.css({ transform: '' });
    
    var tween1 = TweenMax.to($whiteOverlay, 1, 
        {scaleX: 0, ease:Quart.easeInOut}
    );

    $colorOverlay.each(function(index){
        // $(this).css({ transform: '' });
        tween2[index] = TweenMax.fromTo( $(this) , 1, 
            {scaleX: 1},
            {scaleX: 0, ease:Quart.easeInOut, delay: 0 + (index * 0.2), onComplete: function() {
                // $whiteOverlay[0].style.transformOrigin = "left 50% 0px";
            }}
        );
    });

    sceneImage[index] = new ScrollMagic.Scene({
        offset: offset,
        triggerHook: 'onEnter',
        reverse: false
    })
    .setTween( tween1 )
    // .addIndicators() // add indicators (requires plugin)
    .addTo( controller );

    // for( tweens in tween2){
        sceneImage[index] = new ScrollMagic.Scene({
            offset: offset,
            triggerHook: 'onEnter',
            reverse: false
        })
        .setTween( tween2 )
        // .addIndicators()
        .addTo( controller );
    // }

});

var sceneTitle = [];

jQuery('.box-title-number').each(function(index){

    var $boxTitle      = $(this).parent('.box-inner');

    var windowHeight  = $(window).height();
    var elementOffset = $(this).parent().offset().top;
    var elementHeight = $(this).parent().outerHeight();
    var offset        = ( elementOffset - windowHeight );
    
    var tween1 = TweenMax.fromTo($boxTitle, .2, 
        {opacity: 0},
        {opacity: 1, ease:Quart.easeInOut}
    );

    sceneTitle[index] = new ScrollMagic.Scene({
        offset: offset,
        triggerHook: 'onEnter',
        reverse: false
    })
    .setTween( tween1 )
    // .addIndicators() // add indicators (requires plugin)
    .addTo( controller );

});

jQuery('.page-switcher').each(function(){

    jQuery(this).css({
        // top : Math.floor((Math.random() * 100) + 1) + "%",
        left : Math.floor((Math.random() * 100) + 1) + "%"
    })

});

var $pan1           = $('.page-switcher-1');
var $pan2           = $('.page-switcher-2');
var $pan3           = $('.page-switcher-3');
var $pan4           = $('.page-switcher-4');
var $pan5           = $('.page-switcher-5');

var elementEvent = null;

$(document).ready(function() {

    var currentURL      = window.location.href;

    var hideLoader = function() {
        $('body').removeClass('loading');
    };

    var showLoader = function(url) {
        $('body').addClass('loading');
        setTimeout(function(){ 
            window.location.href = url;
        }, 1300);

    };

    $('body').imagesLoaded( { background: true })
    .always( function(instance) {
        hideLoader();
    });


    $('select').selectric({
        maxHeight: 300,
        onChange: function(element) {
            linkHandler( $(this) );
        }
    });

    $('[data-link]').click(function(e) {
        linkHandler( $(this) );
    }).find('a').click(function(e) {
        e.stopPropagation();
    });

    $('a').click(function(e) {
        elementEvent = e;
        linkHandler( $(this) );
    });

    var linkHandler = function(element) {
        var $link = element;

        var url  = ( $link.attr('href') || $link.attr('data-link') || $link.val() );
        
        if ( $link.hasClass('no-link') ||
             $link.hasClass('ab-item') ||
             $link.hasClass('btn-dl') ||
             $link.parent('li').hasClass('current-lang') || 
             $link.attr('target') ||
             url.match('^#') || 
             url.match('^mailto:') || 
             url.match('^tel:') ) {
            // window.location.href = url;
            return;
        }

        if (url === currentURL)
            return;

        if( elementEvent )
            elementEvent.preventDefault();

        // On envoi l’URL vers le loader
        showLoader( url );
    };

});